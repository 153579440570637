import React, { useEffect, useState, useLayoutEffect } from 'react';
// import '../Common/style.css'
import { Image } from 'antd';

import Slider from "react-slick";
function Home() {
    var settings = {
        margin: 0,
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplayspeed: 4000
    };

    const [addClass, setAddClass] = useState('sigma_preloader')


    useEffect(() => {
        setTimeout(() => {
            setAddClass('sigma_preloader hidden')
        }, 1000);

    }, []);

    return (
        <div className="App">

            {/* partial */}
            {/* Banner Start */}
            {/* <div className="sigma_preloader" id='div123'> */}
            <div className={addClass}>
                <img src="assets/img/om.svg" alt="preloader" />
            </div>
            <Slider {...settings}>
                {/* <div>
                    <div className="sigma_banner banner-3">
                        <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/3.jpg")' }}>
                            <div className="sigma_banner-text">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <h1 className="title">Some Important Life Lessons From Gita</h1>
                                            <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Lord Rama and Vishnu Deva the followers and We are a Hindu that belives in Lord Rama and Vishnu Deva. This is where you should start </p>
                                            <div className="section-button d-flex align-items-center">
                                                <a href="contact-us.html" className="sigma_btn-custom">Join Today <i className="far fa-arrow-right" /> </a>
                                                <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="sigma_banner banner-3">
                        <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/3.jpg")' }}>
                            <div className="sigma_banner-text">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <h1 className="title">Some Important Life Lessons From Gita</h1>
                                            <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Lord Rama and Vishnu Deva the followers and We are a Hindu that belives in Lord Rama and Vishnu Deva. This is where you should start </p>
                                            <div className="section-button d-flex align-items-center">
                                                <a href="contact-us.html" className="sigma_btn-custom">Join Today <i className="far fa-arrow-right" /> </a>
                                                <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="sigma_banner banner-3">
                    <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/3.jpg")' }}>
                        <div className="sigma_banner-text">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <h1 className="title">Some Important Life Lessons From Gita1</h1>
                                        <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Hanumanji and Lord Rama the followers. </p>
                                        <div className="section-button d-flex align-items-center">
                                            <a href="https://cspsundarkand.org/book_sundarkand.html" className="sigma_btn-custom">Book Sundarkand <i className="far fa-arrow-right" /> </a>
                                            {/* <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sigma_banner banner-3">
                    <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/5.jpg")' }}>
                        <div className="sigma_banner-text">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <h1 className="title">Some Important Life Lessons From Gita</h1>
                                        <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Hanumanji and Lord Rama the followers  </p>
                                        <div className="section-button d-flex align-items-center">
                                            <a href="https://cspsundarkand.org/book_sundarkand.html" className="sigma_btn-custom">Book Sundarkand<i className="far fa-arrow-right" /> </a>
                                            {/* <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Slider>


            {/*           
            <div className="sigma_banner banner-3">
                <div className="sigma_banner-slider">
           
                    <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/3.jpg")' }}>
                        <div className="sigma_banner-text">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <h1 className="title">Some Important Life Lessons From Gita</h1>
                                        <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Lord Rama and Vishnu Deva the followers and We are a Hindu that belives in Lord Rama and Vishnu Deva. This is where you should start </p>
                                        <div className="section-button d-flex align-items-center">
                                            <a href="contact-us.html" className="sigma_btn-custom">Join Today <i className="far fa-arrow-right" /> </a>
                                            <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/5.jpg")' }}>
                        <div className="sigma_banner-text">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <h1 className="title">We are a Hindu that believe in Ram</h1>
                                        <p className="blockquote mb-0 bg-transparent"> We are a Hindu that belives in Lord Rama and Vishnu Deva the followers and We are a Hindu that belives in Lord Rama and Vishnu Deva. This is where you should start </p>
                                        <div className="section-button d-flex align-items-center">
                                            <a href="contact-us.html" className="sigma_btn-custom">Join Today <i className="far fa-arrow-right" /> </a>
                                            <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
           */}

            {/* Banner End */}
            {/* About Start */}
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-lg-30">
                            <div className="img-group">
                                <div className="img-group-inner">
                                    {/* <img src="assets/img/about-group1/1.jpg" alt="about" data-aos='fade-right' /> */}
                                    <img src="assets/img/dadupur/1.jpg" alt="about" data-aos='fade-right' width={300} />

                                </div>
                                <img src="assets/img/dadupur/2.jpg" alt="about" data-aos='fade-up' width={200} />
                                <img src="assets/img/dadupur/4.jpg" alt="about" data-aos='fade-left' width={200} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="me-lg-30">
                                <div className="section-title mb-0 text-start">
                                    <p className="subtitle">Dadupur Hanumani Temple</p>
                                    <h4 className="title">We are a Hindu that believe in Hanumaji anf Lord Ram</h4>
                                </div>
                                <ul className="sigma_list list-2 mb-0">
                                    <li>Peace of Mind</li>
                                    <li>Set For Pastor</li>
                                    <li>100% Satisfaction</li>
                                    <li>Trusted</li>
                                </ul>
                                <p className="blockquote bg-transparent"> Dadupur Hanumanji temple was established by Chudamani Sundarkand Parivar on 10th April,2022. Thousands of devotees visit this miraculous temple and feel graced </p>
                                {/* <a href="about-us.html" className="sigma_btn-custom light">Learn More <i className="far fa-arrow-right" /> </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About End */}
            {/* holi Start */}
            <div className="section section-padding pattern-squares dark-bg-2">
                <div className="container">
                    <div className="section-title text-start">
                        <p className="subtitle">service</p>
                        <h4 className="title text-white">How We Can Help</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6" data-aos='fade-right'>
                            <a href="#" className="sigma_service style-1 primary-bg">
                                <div className="sigma_service-thumb">
                                    <i className="text-white flaticon-temple" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5 className="text-white">About Temple--111</h5>
                                    <p className="text-white">Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                </div>
                                <span className="btn-link text-white">Learn More <i className="text-white far fa-arrow-right" /> </span>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-negative-sm" data-aos='fade-down'>
                            <a href="#" className="sigma_service style-1 secondary-bg">
                                <div className="sigma_service-thumb">
                                    <i className="custom-primary flaticon-hindu-1" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5 className="text-white">Our Pandit</h5>
                                    <p className="text-white">Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                </div>
                                <span className="text-white btn-link">Learn More <i className="text-white far fa-arrow-right" /> </span>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-negative-sm" data-aos='fade-lest'>
                            <a href="#" className="sigma_service style-1 bg-white">
                                <div className="sigma_service-thumb">
                                    <i className="flaticon-pooja" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5>Prayers</h5>
                                    <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                </div>
                                <span className="btn-link">Learn More <i className="far fa-arrow-right" /> </span>
                            </a>
                        </div>
                    </div>
                    {/* <div className="text-end">
                        <a href="services.html" className="btn-link text-white"> Get Started Now <i className="custom-primary far fa-arrow-right" /> </a>
                    </div> */}
                </div>
            </div>
            {/* holi End */}
            {/* Progress bar Start */}
            <div className="section">
                {/* <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <img src="assets/img/progress-bar/1.jpg" alt="img" />
                        </div>
                        <div className="col-lg-4 p-3 p-lg-0">
                            <div className="sigma_box sigma_box-absolute sigma_box-lg m-0 sigma_box-progress">
                                <i className="flaticon-hindu" />
                                <div className="sigma_round-button mb-4">
                                    <span> <b className="counter" data-from={0} data-to={75}>0</b> <span className="custom-primary">%</span> </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                        <circle className="sigma_round-button-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                        <circle className="sigma_progress-round sigma_round-button-circle" data-to={290} strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                    </svg>
                                </div>
                                <h6>Donnation Success Rate</h6>
                                <p className="m-0 blockquote bg-transparent"> Design better websites and spend less time without restricting </p>
                            </div>

                        </div>
                        <div className="col-lg-4 z-index-3">
                            <img src="assets/img/progress-bar/2.jpg" alt="img" />
                        </div>
                    </div>
                </div> */}
                <div className="section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                {/* <img src="assets/img/progress-bar/1.jpg" alt="img" /> */}
                                <img src="assets/img/dadupur/1.jpg" alt="img" width={300} />
                            </div>
                            <div className="col-lg-4 p-3 p-lg-0">
                                <img src="assets/img/dadupur/upi.jpeg" alt="img" width={330} />
                            </div>
                            <div className="col-lg-4 z-index-3">
                                {/* <img src="assets/img/progress-bar/2.jpg" alt="img" /> */}
                                <img src="assets/img/dadupur/2.jpg" alt="img" width={300} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Progress bar End */}
            {/* Donation Start */}
            {/* <div className="section section-padding pt-0">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="subtitle">Make a Donation</p>
                        <h4 className="title">Donate Us To Help</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="sigma_service style-2">
                                <div className="sigma_service-thumb">
                                    <img src="assets/img/donation/5.jpg" alt="img" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5>
                                        <a href="donation.html">Protecting Children</a>
                                    </h5>
                                    <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    <div className="sigma_service-progress">
                                        <div className="progress-content">
                                            <p>Raised: $52,384</p>
                                            <p>Goal: $85,000</p>
                                        </div>
                                        <div className="sigma_progress">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={84} aria-valuemin={0} aria-valuemax={100}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="donation.html" className="sigma_btn-custom">
                                        Donate
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="sigma_service style-2">
                                <div className="sigma_service-thumb">
                                    <img src="assets/img/donation/6.jpg" alt="img" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5>
                                        <a href="donation.html">Donate Ngos</a>
                                    </h5>
                                    <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    <div className="sigma_service-progress">
                                        <div className="progress-content">
                                            <p>Raised: $49,444</p>
                                            <p>Goal: $78,000</p>
                                        </div>
                                        <div className="sigma_progress">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="donation.html" className="sigma_btn-custom">
                                        Donate
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="sigma_service style-2">
                                <div className="sigma_service-thumb">
                                    <img src="assets/img/donation/7.jpg" alt="img" />
                                </div>
                                <div className="sigma_service-body">
                                    <h5>
                                        <a href="donation.html">Feed Poors</a>
                                    </h5>
                                    <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    <div className="sigma_service-progress">
                                        <div className="progress-content">
                                            <p>Raised: $78,334</p>
                                            <p>Goal: $96,400</p>
                                        </div>
                                        <div className="sigma_progress">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={94} aria-valuemin={0} aria-valuemax={100}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="donation.html" className="sigma_btn-custom">
                                        Donate
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Donation End */}
            {/* volunteers Start */}
            <section className="section section-padding light-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-lg-30">
                            <div className="section-title mb-0 text-start">
                                <p className="subtitle">volunteers</p>
                                <h4 className="title">Our Volunteers Achievments</h4>
                            </div>
                            <p className="blockquote bg-transparent"> We are a Hindu that belives in Hanumanji and Lord Rama the followers. </p>
                            {/* <div className="basic-dot-slider light-dots"> */}
                            <div className="slide-item">
                                <p className="mb-0">Rajubhai Patel is fully devoted to Lord Hanumanji from his early childhood. With a Divine grace of god, he started recting Sundarkand at the age of 21 at public platform. </p>
                            </div>
                            <div className="slide-item">
                                <p className="mb-0">Rajubhai  formed a Parivar –CSP. Rajubhai  has recited Sundarkand in many villages and cities of Western India.</p>
                            </div>
                            <div className="slide-item">
                                <p className="mb-0">With the inspiration of Almighty , he presented the idea of constructing Dadupur Hanumanji temple. Many people joined this Maha Yagna enthusiasticallyand construction of Dadupur Hanumanji temple took place easily.</p>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="me-lg-30">
                                <div className="row">
                                    {/* <div className="col-lg-6 col-md-6">
                                        <div className="sigma_volunteers volunteers-5">
                                            <div className="sigma_volunteers-thumb">
                                                <img src="assets/img/volunteers/1.jpg" alt="volunteers" />
                                                <ul className="sigma_sm">
                                                    <li> <a href="#" className="trigger-volunteers-socials"> <i className="fal fa-plus" /> </a> </li>
                                                    <li> <a href="#"> <i className="fab fa-facebook-f" /> </a> </li>
                                                    <li> <a href="#"> <i className="fab fa-twitter" /> </a> </li>
                                                    <li> <a href="#"> <i className="fab fa-instagram" /> </a> </li>
                                                </ul>
                                            </div>
                                            <div className="sigma_volunteers-body">
                                                <div className="sigma_volunteers-info">
                                                    <p>Temple Memember</p>
                                                    <h5>
                                                        <a href="volunteer-detail.html">Yesh Chopra</a>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="sigma_volunteers volunteers-5">
                                            <div className="sigma_volunteers-thumb">
                                                <img src="assets/img/dadupur/3.jpeg" alt="volunteers" />
                                                <ul className="sigma_sm">
                                                    {/* <li> <a href="#" className="trigger-volunteers-socials"> <i className="fal fa-plus" /> </a> </li> */}
                                                    <li> <a href="#"> <i className="fab fa-facebook-f" /> </a> </li>
                                                    <li> <a href="#"> <i className="fab fa-twitter" /> </a> </li>
                                                    <li> <a href="#"> <i className="fab fa-instagram" /> </a> </li>
                                                </ul>
                                            </div>
                                            <div className="sigma_volunteers-body">
                                                <div className="sigma_volunteers-info">
                                                    <p>Temple Memember</p>
                                                    <h5>
                                                        <a href="volunteer-detail.html">Rajubhai Patel</a>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* volunteers End */}
            {/* Puja Start */}
            <div className="section section-padding">
                <div className="container">
                    <div className="section-title text-start">
                        <p className="subtitle">Portfolio</p>
                        <h4 className="title">Puja</h4>
                    </div>
                    <div className="text-center filter-items left">
                        <h5 className="active portfolio-trigger" data-filter="*">All</h5>
                        {/* <h5 className="portfolio-trigger" data-filter=".coaching">Durga Puja</h5>
                        <h5 className="portfolio-trigger" data-filter=".stakeholder">Raksha Bandhan</h5>
                        <h5 className="portfolio-trigger" data-filter=".strategy">Holi</h5>
                        <h5 className="portfolio-trigger" data-filter=".relations">Diwali</h5> */}
                    </div>
                    <div className="portfolio-filter row">
                        <div className="col-lg-4 coaching">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/1.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/1.jpeg"
                                />
                                {/* <div className="sigma_portfolio-item-content">
                                    <div className="sigma_portfolio-item-content-inner">
                                        <h5> <a href="puja-details.html"> Deepavali Diwalli </a> </h5>
                                    </div>
                                    <a href="puja-details.html"><i className="far fa-arrow-right" /></a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>
                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/4.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>

                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/5.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>
                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/6.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>
                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/7.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>

                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/8.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>

                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/9.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>

                        <div className="col-lg-4 strategy">
                            <div className="sigma_portfolio-item style-2">
                                {/* <img src="assets/img/puja/style-2/2.jpg" alt="portfolio" /> */}
                                <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/3.jpeg"
                                    alt="portfolio"
                                />
                                {/*  <Image
                                    width={300}
                                    src="assets/img/dadupur/photos/2.jpeg"
                                /> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* Puja End */}
            {/* Testimonials Start */}
            {/* <section className="section pt-0">
                <div className="container testimonial-section bg-contain bg-norepeat bg-center" style={{ backgroundImage: 'url(assets/img/textures/map-2.png)' }}>
                    <div className="section-title text-center">
                        <p className="subtitle">Testimonials</p>
                        <h4 className="title">What Our Congregation Say</h4>
                    </div>
                    <div className="sigma_testimonial style-2">
                        <div className="sigma_testimonial-slider">
                            <div className="sigma_testimonial-inner">
                                <div className="sigma_testimonial-thumb">
                                    <img src="assets/img/testimonials/1.jpg" alt="testimonial" />
                                </div>
                                <div>
                                    <div className="sigma_testimonial-body">
                                        <div className="sigma_rating-wrapper">
                                            <div className="sigma_rating">
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="far fa-star" />
                                            </div>
                                        </div>
                                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                                    </div>
                                    <div className="sigma_testimonial-footer">
                                        <div className="sigma_testimonial-author">
                                            <cite>Mukesh Singh</cite>
                                            <span>Pandit</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sigma_testimonial-inner">
                                <div className="sigma_testimonial-thumb">
                                    <img src="assets/img/testimonials/2.jpg" alt="testimonial" />
                                </div>
                                <div>
                                    <div className="sigma_testimonial-body">
                                        <div className="sigma_rating-wrapper">
                                            <div className="sigma_rating">
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="far fa-star" />
                                            </div>
                                        </div>
                                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                                    </div>
                                    <div className="sigma_testimonial-footer">
                                        <div className="sigma_testimonial-author">
                                            <cite>Das Gupta</cite>
                                            <span>Executive</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sigma_testimonial-inner">
                                <div className="sigma_testimonial-thumb">
                                    <img src="assets/img/testimonials/3.jpg" alt="testimonial" />
                                </div>
                                <div>
                                    <div className="sigma_testimonial-body">
                                        <div className="sigma_rating-wrapper">
                                            <div className="sigma_rating">
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="fas fa-star active" />
                                                <i className="far fa-star" />
                                            </div>
                                        </div>
                                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                                    </div>
                                    <div className="sigma_testimonial-footer">
                                        <div className="sigma_testimonial-author">
                                            <cite>Mukesh Singh</cite>
                                            <span>Pandit</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <div className="sigma_arrows style-2">
                            <i className="far fa-chevron-left slick-arrow slider-prev" />
                            <i className="far fa-chevron-right slick-arrow slider-next" />
                        </div>
                    </div>
                </div>
            </section> */}
            {/* Testimonials End */}
            {/* CTA Start */}
            <div className="section pt-0" style={{ marginTop: 30}}>
                <div className="container">
                    <div className="section-title text-center">
                        <p className="subtitle">WAYS WE CAN HELP</p>
                        <h4 className="title">Angels Ready To Help</h4>
                    </div>
                    <div className="row align-items-center position-relative">
                        <div className="col-md-6">
                            <div className="sigma_cta primary-bg">
                                <img className="d-none d-lg-block" src="assets/img/cta/1.png" alt="cta" />
                                <div className="sigma_cta-content">
                                    <span className="fw-600 custom-primary">Need Help, Call Our HOTLINE!</span>
                                    <h4 className="text-white">+942-818-505-0</h4>
                                </div>
                            </div>
                        </div>
                        <span className="sigma_cta-sperator d-none d-lg-flex"></span>
                        <div className="col-md-6">
                            <div className="sigma_cta primary-bg">
                                <div className="sigma_cta-content">
                                    <form method="post">
                                        <label className="mb-0 text-white">Dadupur Hanumani Tample</label>
                                        <div className="sigma_search-adv-input">
                                            {/* <input type="text" className="form-control" placeholder="Enter email address" name="search" defaultValue /> */}
                                            {/* <button type="submit" name="button"><i className="fa fa-search" /></button> */}
                                        </div>
                                    </form>
                                </div>
                                <img className="d-none d-lg-block" src="assets/img/cta/2.png" alt="cta" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* CTA End */}
            {/* Form Start */}
            <div className="section dark-overlay dark-overlay-3 bg-cover bg-center bg-norepeat" style={{ backgroundImage: 'url(assets/img/bg1.jpg)' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-lg-30">
                            <form method="post">
                                <div className="form-row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <i className="far fa-user" />
                                            <input type="text" className="form-control transparent" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <i className="far fa-user" />
                                            <input type="text" className="form-control transparent" placeholder="Last Name" name="lname" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <i className="far fa-pencil" />
                                            <input type="text" className="form-control transparent" placeholder="Mobile" name="subject" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <i className="far fa-envelope" />
                                            <input type="text" className="form-control transparent" placeholder="Email Address" name="email" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control transparent" placeholder="Enter Message" rows={4} defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        {/* <button type="submit" className="sigma_btn-custom d-block w-100" name="button"> Book Sundarkand <i className="far fa-arrow-right" /> </button> */}
                                        <a href="https://cspsundarkand.org/book_sundarkand.html" className="sigma_btn-custom d-block w-100">Book Sundarkand<i className="far fa-arrow-right" /> </a>

                                    </div>
                                    {/* <div className="section-button d-flex align-items-center">
                                        <a href="services.html" className="ms-3 sigma_btn-custom white">View Services <i className="far fa-arrow-right" /> </a>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6" >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/dadupur/4.jpg" alt="client" />
                                    </div>
                                </div>
                                {/* <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/clients/2.png" alt="client" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/clients/3.png" alt="client" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/clients/4.png" alt="client" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/clients/5.png" alt="client" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sigma_client">
                                        <img src="assets/img/clients/6.png" alt="client" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Form End */}
            {/* Blog Start */}
            <div className="section section-padding">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="subtitle">Blog</p>
                        <h4 className="title">News Feed</h4>
                    </div>
                    <div className="row">
                        {/* Article Start */}
                        <div className="col-lg-4 col-md-6" data-aos='fade-right'>
                            {/* <article className="sigma_post">
                                <div className="sigma_post-thumb">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="post" />
                                    </a>
                                </div>
                                <div className="sigma_post-body">
                                    <div className="sigma_post-meta">
                                        <div className="me-3">
                                            <i className="fas fa-om" />
                                            <a href="blog-details.html" className="sigma_post-category">Temple</a>,
                                            <a href="blog-details.html" className="sigma_post-category">Love</a>
                                        </div>
                                        <a href="blog-details.html" className="sigma_post-date"> <i className="far fa-calendar" /> May 20, 2022</a>
                                    </div>
                                    <h5> <a href="blog-details.html">Temple companies are being so transparent with their work</a> </h5>
                                    <div className="sigma_post-single-author">
                                        <img src="assets/img/people/1.jpg" alt="author" />
                                        <div className="sigma_post-single-author-content">
                                            By <p>Miha Boukor</p>
                                        </div>
                                    </div>
                                </div>
                            </article> */}
                        </div>

                        {/* Article End */}
                    </div>
                </div>
                <div className="spacer spacer-bottom spacer-lg light-bg pattern-triangles" />
            </div>
            {/* Blog End */}
            {/* Back To Top Start */}
            <div className="sigma_top style-5">
                <i className="far fa-angle-double-up" />
            </div>
            {/* Back To Top End */}
            {/* Audio Box Start */}
            {/* <div class="sigma_audio-box">
<div id="audio-btn">
<i class="fa fa-play"> </i>
<i class="fa fa-music"></i>
</div>
</div> */}

        </div>


    );
}

export default Home;
