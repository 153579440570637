import React, { useEffect, useState } from 'react';

import { Carousel, Form, Input, Badge, Calendar, Layout, Button, Modal, Row, Col } from 'antd';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, useLoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import TampleServices from "../Services/TampleServices"
// import GoogleMapReact from "google-maps-react";


const { Header, Content, Footer } = Layout;
const getMonthData = (value) => {
    if (value.month() === 8) {
        return 1394;
    }
};

const libraries = ['places'];
const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};
// const center = {
//   lat: 7.2905715, // default latitude
//   lng: 80.6337262, // default longitude
// };

function SundarkandBook() {
    const [isShow, setIsShow] = useState(true)
    const [from, setFrom] = useState({
        firstName: '',
        middleName: '',
        LastName: '',
        mobileNo1: '',
        mobileNo2: '',
        address: '',
        city: '',
        pincode: ''
    })
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenGoogle, setIsOpenGoogle] = useState(false);
    const [records, setRecords] = useState([]);
    const [tampleServices] = useState(() => new TampleServices())
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [count, setCount] = useState(0);
    const [center, setCenter] = React.useState({
        lat: position.latitude,// "23.649912",
        lng: position.longitude // "73.041192",
    });
    const [addClass, setAddClass] = useState('sigma_preloader')
    const showToastMessage = () => {
        toast.success('Successfully toasted!')
    };
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'YOUR_API_KEY',
    //     libraries,
    //   });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBUTyzgVUmne6o33NEOdGIVIWXqr9p_Bgs",
        libraries
    })
    const notify = () => toast("Wow so easy!");

    useEffect(() => {

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }

        showToastMessage()
        setTimeout(() => {
            setAddClass('sigma_preloader hidden')
        }, 1000);
        getBokkingData()
    }, [])



    const getBokkingData = () => {
        let ccc = position;
        // tampleServices.GetRecords().then((res) => {
        //     debugger;
        //     let records = res;
        // })
        let baseURL = "https://services.dadupur.com/api/TampleServices/GetAllRecords";
        axios.get(baseURL).then((response) => {
            setRecords(response.data);
            // fillCalender(response.data)
        })
            .catch(error =>
                console.log(error));
        ;
    }

    const getListData = (value) => {
        let listData;
        let day = value.date();
        // listData = records.filter(x => getDay(x.bokingDate) == value.date())
        listData = records.filter(x => getDay(x.bokingDate, value.toDate()))
        return listData || [];
    };

    const getDay = (date, date1) => {
        var today = new Date(date);
        today.setHours(0, 0, 0, 0);
        let d = new Date(date1);
        d.setHours(0, 0, 0, 0);
        return today.toDateString() == d.toDateString() ? true : false;
        // var d = new Date(date)
        // let day = d.getDay();
        // debugger
        // return d.getDay();
    }


    const monthCellRender = (value) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };
    const dateCellRender = (value) => {
        // const listData = getListData(value);
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.id}>
                        <Badge status="success" text={item?.firstName + " " + item?.middleName} />
                    </li>
                ))}
            </ul>
        );
    };
    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };

    const onFinish = (from) => {
        var res = from;

        // event.preventDefault();

        const user = {
            name: this.state.name
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { user })
            .then(res => {
                console.log(res);
                console.log(res.data);
            })

    }

    const clickOnDate = (current, info) => {
        if (["year", 'month'].includes(info?.source)) return;
        let listData = records.filter(x => getDay(x.bokingDate, current.toDate()))
        setCount(listData.length);
        setIsOpen(true)
    };


    const clickOnButton = (e) => {
        e.stopPropagation();
        alert("Clicked on button!");
    };

    const openModel = () => {
        return <Modal
            title={count >= 2 ? <span>Sunderkand booking closed</span> : <span>Sunderkand booking</span>}
            centered
            open={isOpen}
            // onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            width={1000}
        >
            <>
                {count >= 2 ? <span>This date is closed</span> : <Form
                    layout="horizontal"
                    name="basic"

                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="sigma_box box-lg m-0 mf_form_validate ajax_submit size-max"
                >

                    {/* <form className="sigma_box box-lg m-0 mf_form_validate ajax_submit" action="https://metropolitanhost.com/themes/themeforest/html/maharatri/sendmail.php" method="post" encType="multipart/form-data"> */}
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group" >
                                {/* <i className="far fa-user" />
                                        <input type="text" placeholder="Full Name" className="form-control dark" name="name" /> */}
                                {/* <Form.Item
                                            label="Fisrt Name"
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                            className="form-control dark text-input"
                                        >
                                            <Input />
                                        </Form.Item> */}
                                <Form.Item
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please input your First Name!' }]}
                                    className="form-control dark text-input padding-zero"
                                >
                                    <Input placeholder="First Name" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="middleName"
                                    rules={[{ required: true, message: 'Please input your Middle Name!' }]}
                                    className="form-control dark text-input padding-zero"
                                >
                                    <Input placeholder="Middle Name" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please input your Last Name!' }]}
                                    className="form-control dark text-input padding-zero"
                                >
                                    <Input placeholder="Last Name" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="mobileNumber-1"
                                    rules={[{ required: true, message: 'Please input your Mobile Number-1!' }]}
                                    className="form-control dark text-input padding-zero"
                                >
                                    <Input placeholder="Mobile Number-1" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="mobileNumber-2"
                                    rules={[{ required: true, message: 'Please input your Mobile Number-2!' }]}
                                    className="form-control dark text-input padding-zero"
                                >
                                    <Input placeholder="Mobile Number-2" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="address"
                                    rules={[{ required: true, message: 'Please input your Address!' }]}
                                    className="form-control dark text-input  padding-zero"
                                >
                                    <Input placeholder="Address" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="city"
                                    rules={[{ required: true, message: 'Please input your City!' }]}
                                    className="form-control dark text-input  padding-zero"
                                >
                                    <Input placeholder="City" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                {/* <i className="far fa-envelope" />
                                        <input type="email" placeholder="Email Address" className="form-control dark" name="email" /> */}
                                <Form.Item
                                    name="pincode"
                                    rules={[{ required: true, message: 'Please input your Pincode!' }]}
                                    className="form-control dark text-input  padding-zero"
                                >
                                    <Input placeholder="Pincode" className="form-control dark text-input" />
                                </Form.Item>
                            </div>
                        </div>

                        {/* <div className="col-lg-4">
                                    <div className="form-group">
                                        <i className="far fa-pencil" />
                                        <input type="text" placeholder="Subject" className="form-control dark" name="Subesubject" />
                                    </div>
                                </div> */}
                    </div>
                    <div className="text-center" style={{ marginTop: 10 }}>
                        <button type="submit" className="sigma_btn-custom" name="button">Submit Now</button>
                        {/* <div className="server_response w-100">
                            <button type="button" className="sigma_btn-custom" name="button" onClick={() => setIsOpenGoogle(true)}>Check your place</button>
                        </div> */}
                    </div>
                </Form>}
            </>
        </Modal>
    }

    const openModelForGoogle = () => {
        return <Modal
            title={count >= 2 ? <span>Sunderkand booking closed</span> : <span>Sunderkand booking</span>}
            centered
            open={isOpenGoogle}
            onOk={() => setIsOpenGoogle(false)}
            onCancel={() => setIsOpenGoogle(false)}
            width={1000}
        >
            <Row>
                <Col span={24}>
                    {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDrUzseSk0GCuCEiOJdWFG60jFR4ncYcBI" }}
                    defaultCenter={{ lat: position.latitude, lng: position.longitude }}
                    defaultZoom={15}
                >
                    <Marker position={{ lat: position.latitude, lng: position.longitude }} />
                </GoogleMapReact> */}

                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                        style={{ width: 100 }}
                    >
                        <Marker position={center} />
                    </GoogleMap>

                </Col>
            </Row>
        </Modal>
    }

    return (
        <div className="App">
            <div>
                {openModel()}
                {/* {openModelForGoogle()} */}
                {/* Preloader Start */}
                {/* <div className="sigma_preloader" style={{ display: isShow ? 'block' : 'none' }}> */}
                <div className={addClass}>
                    <img src="assets/img/om.svg" alt="preloader" />
                </div>
                <div className="sigma_aside-overlay aside-trigger-left" />
                <div className="sigma_subheader dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(assets/img/subheader.jpg)' }}>
                    <div className="container">
                        <div className="sigma_subheader-inner">
                            <div className="sigma_subheader-text">
                                <h1>
                                    <br />
                                    <br />
                                </h1>
                            </div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="btn-link" href="#">Dadupur</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Book Sundarkand</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* partial */}
                {/* Map Start */}
                <div className="sigma_map">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin" allowFullScreen /> */}
                    <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Dadupur%20hanumanji%20tample,%20himatnagar+(Dadupur%20Hanumanji%20Tanple)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe>
                </div>
                {/* Map End */}
                {/* Contact form Start */}
                <div className="section1 mt-negative pt-0">
                    <div className="container">
                        <div style={{ marginLeft: 5, marginRight: 5, paddingTop: 5, overflow: 'auto hidden' }}>

                            <Calendar cellRender={cellRender}
                                onSelect={clickOnDate}
                                fullscreen={true}
                                style={{
                                    width: 1250,
                                    minWidth: "100%"
                                }}
                            />

                        </div>
                    </div>

                    {/* <Content
                        style={{
                            margin: '24px 16px',
                            padding: 0,
                            minHeight: 280,
                            // background: "green",
                            borderRadius: 1,
                        }}


                    >
                        <div className='childXFill'>
                            <Calendar cellRender={cellRender} />

                        </div>
                    </Content> */}
                    {/* <div className="row">
                        <div className="col-lg-8">
                            <div className="">
                                <Calendar cellRender={cellRender} />
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* Contact form End */}
                {/* Icons Start */}
                <div className="section section-padding pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4" data-aos='fade-right'>
                                <div className="sigma_icon-block text-center light icon-block-7">
                                    <i className="flaticon-email" />
                                    <div className="sigma_icon-block-content">
                                        {/* <span>Send Email <i className="far fa-arrow-right" /> </span>
                                        <h5> Email Address</h5>
                                        <p>info@example.com</p>
                                        <p>info@support.com</p> */}

                                        <span style={{ fontSize: 20 }}>સુંદરકાંડ ના પાઠ ની તૈયારીઓ<i className="far fa-arrow-right" /> </span>
                                        <span>પાંચ ફૂલહાર (2 મોટા અને 3 નાના)</span>
                                        <span>પાંચ અલગ અલગ ફળ</span>
                                        <span>એક અખંડ દીવો(તેલનો), આરતી(ઘીમાં), અગરબતી</span>
                                        <span>તાંબા ના કળશ માં પાણી</span>
                                        <span>ગોળ-ચણા ની પ્રસાદી. બીજી પ્રસાદી ઈચ્છા પ્રમાણે.</span>
                                        <span>લાઈટ હેલોજન અને પાથરણા</span>
                                        <span>સંભાળ ખર્ચ (મૈન્ટેનન્સ) - ₹ 5100/- રૂપિયા (સાઉન્ડ સિસ્ટમ + પુસ્તક + ચાલીસા - માત્ર હિંમતનગર માટે)</span>
                                    </div>
                                    {/* <div className="icon-wrapper">
                                        <i className="flaticon-email" />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4" data-aos='fade-down'>
                                <div className="sigma_icon-block text-center light icon-block-7">
                                    {/* <i className="flaticon-call" />   */}
                                    <div className="sigma_icon-block-content" style={{ height: 335 }}>
                                        <span style={{ fontSize: 20 }}>Call Us Now <i className="far fa-arrow-right" /> </span>
                                        {/* <h1> Phone Number </h1> */}
                                        {/* <i className="fal fa-phone" /> */}
                                        <br />
                                        <i className="flaticon-call" />
                                        <span style={{ fontSize: 20 }}>રાજુભાઈ</span>
                                        <span>942-818-505-0</span>
                                        <div className="icon-wrapper">

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4" data-aos='fade-left'>
                                <div className="sigma_icon-block text-center light icon-block-7">
                                    {/* <i className="flaticon-location" /> */}
                                    <div className="sigma_icon-block-content" style={{ height: 335 }}>
                                        {/* <span>Find Us Here <i className="far fa-arrow-right" /> </span>
                                        <h5> Location </h5>
                                        <p>16/A Daddy Yankee Tower</p>
                                        <p>New York, US</p> */}
                                        <span style={{ fontSize: 20 }}>Address<i className="far fa-arrow-right" /> </span>
                                        <span>Dadupur, Hanumanji Temple,</span>
                                        <span>Khed Tasiya Road, Near Rampura -</span>
                                        <span> Himatnagar - Gujarat.</span>

                                    </div>
                                    {/* <div className="icon-wrapper">
                                        <i className="flaticon-location" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Icons End */}
                {/* partial:partia/__footer.html */}

            </div>

        </div>


    );
}

export default SundarkandBook;
