import React, { useEffect, useState, useLayoutEffect } from 'react';
// import '../Common/style.css'

import Slider from "react-slick";
function Home() {
    var settings = {
        margin: 0,
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplayspeed: 4000
    };

    const [addClass, setAddClass] = useState('sigma_preloader')


    useEffect(() => {
        setTimeout(() => {
            setAddClass('sigma_preloader hidden')
        }, 1000);

    }, []);

    return (
        <div className="App">
            <div>
                {/* <div className="sigma_preloader">
                    <img src="assets/img/om.svg" alt="preloader" />
                </div> */}
                <div className={addClass}>
                    <img src="assets/img/om.svg" alt="preloader" />
                </div>

                <aside className="sigma_aside sigma_aside-left">
                    <a className="navbar-brand" href="index-2.html"> <img src="assets/img/logo.png" alt="logo" /> </a>
                    {/* Menu */}
                    <ul>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Home</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="index-2.html">Home v1</a> </li>
                                <li className="menu-item"> <a href="home-v2.html">Home v2</a> </li>
                                <li className="menu-item"> <a href="home-v3.html">Home v3</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Blog</a>
                            <ul className="sub-menu">
                                <li className="menu-item">
                                    <a href="blog-grid.html">Blog Archive</a>
                                </li>
                                <li className="menu-item"> <a href="blog-details.html">Blog Details</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Pages</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="about-us.html">About Us</a> </li>
                                <li className="menu-item"> <a href="broadcast.html">Broadcast</a> </li>
                                <li className="menu-item menu-item-has-children">
                                    <a href="#">Volunteers</a>
                                    <ul className="sub-menu">
                                        <li className="menu-item">
                                            <a href="volunteers.html">Volunteers</a>
                                        </li>
                                        <li className="menu-item"> <a href="volunteer-detail.html">Volunteer Details</a> </li>
                                    </ul>
                                </li>
                                <li className="menu-item"> <a href="faq.html">FAQ</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Service</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="services.html">Service Style 1</a> </li>
                                <li className="menu-item"> <a href="services-2.html">Service Style 2</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Donation</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="donation.html">Donation</a> </li>
                                <li className="menu-item"> <a href="donation-archive.html">Donation Archive</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Event</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="events.html">Event</a> </li>
                                <li className="menu-item"> <a href="event-details.html">Event Detail</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Holis</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="holi.html">Holi</a> </li>
                                <li className="menu-item"> <a href="holi-details.html">Holi Details</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Puja</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="puja.html">Puja</a> </li>
                                <li className="menu-item"> <a href="puja-details.html">Puja Details</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <a href="#">Shop</a>
                            <ul className="sub-menu">
                                <li className="menu-item"> <a href="shop.html">Shop</a> </li>
                                <li className="menu-item"> <a href="product-single.html">Product Details</a> </li>
                                <li className="menu-item"> <a href="cart.html">Cart</a> </li>
                                <li className="menu-item"> <a href="checkout.html">Checkout</a> </li>
                                <li className="menu-item"> <a href="wishlist.html">Wishlist</a> </li>
                            </ul>
                        </li>
                        <li className="menu-item">
                            <a href="contact-us.html">Contact</a>
                        </li>
                    </ul>
                </aside>
                <div className="sigma_aside-overlay aside-trigger-left" />
                {/* partial */}
                {/* partial:partia/__header.html */}
                <header className="sigma_header header-3 can-sticky header-absolute">
                    {/* Middle Header Start */}
                    <div className="sigma_header-middle">
                        <nav className="navbar">
                            {/* Logo Start */}
                            <div className="sigma_logo-wrapper">
                                <a className="navbar-brand" href="index-2.html">
                                    <img src="assets/img/logo.png" alt="logo" />
                                </a>
                            </div>
                            {/* Logo End */}
                            {/* Menu */}
                            <div className="sigma_header-inner">
                                <div className="sigma_header-top">
                                    <div className="sigma_header-top-inner">
                                        <ul className="sigma_header-top-links">
                                            <li className="menu-item"> <a href="tel:+123456789"> <i className="fal fa-phone" /> (+123) 123 4567 890</a> </li>
                                            <li className="menu-item"> <a href="mailto:info@example.com"> <i className="fal fa-envelope" /> info@domain.com</a> </li>
                                        </ul>
                                        <ul className="navbar-nav">
                                            <li className="menu-item menu-item-has-children">
                                                <a href="#">Event</a>
                                                <ul className="sub-menu">
                                                    <li className="menu-item"> <a href="events.html">Event</a> </li>
                                                    <li className="menu-item"> <a href="event-details.html">Event Detail</a> </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-has-children">
                                                <a href="#">Holis</a>
                                                <ul className="sub-menu">
                                                    <li className="menu-item"> <a href="holi.html">Holi</a> </li>
                                                    <li className="menu-item"> <a href="holi-details.html">Holi Details</a> </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-has-children">
                                                <a href="#">Puja</a>
                                                <ul className="sub-menu">
                                                    <li className="menu-item"> <a href="puja.html">Puja</a> </li>
                                                    <li className="menu-item"> <a href="puja-details.html">Puja Details</a> </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul className="sigma_header-top-links">
                                            <li className="d-flex align-items-center">
                                                <a href="broadcast.html" className="live">
                                                    <i className="fa fa-circle" />
                                                    Live
                                                </a>
                                            </li>
                                            <li className="menu-item menu-item-has-children d-flex align-items-center">
                                                <img src="assets/img/flag.png" alt="img" />
                                                <a href="#" className="m-0"> Language</a>
                                                <ul className="sub-menu sub-menu-left">
                                                    <li> <a href="#">English</a> </li>
                                                    <li> <a href="#">German</a> </li>
                                                    <li> <a href="#">French</a> </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center justify-content-lg-between">
                                    <ul className="navbar-nav">
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Home</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"> <a href="index-2.html">Home v1</a> </li>
                                                <li className="menu-item"> <a href="home-v2.html">Home v2</a> </li>
                                                <li className="menu-item"> <a href="home-v3.html">Home v3</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item"> <a href="about-us.html">About</a> </li>
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Blog</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item">
                                                    <a href="blog-grid.html">Blog Archive</a>
                                                </li>
                                                <li className="menu-item"> <a href="blog-details.html">Blog Details</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Pages</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"> <a href="broadcast.html">Broadcast</a> </li>
                                                <li className="menu-item"> <a href="volunteers.html">Volunteers</a> </li>
                                                <li className="menu-item"> <a href="volunteer-detail.html">Volunteer Details</a> </li>
                                                <li className="menu-item"> <a href="faq.html">FAQ</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Service</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"> <a href="services.html">Service Style 1</a> </li>
                                                <li className="menu-item"> <a href="services-2.html">Service Style 2</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Donation</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"> <a href="donation.html">Donation</a> </li>
                                                <li className="menu-item"> <a href="donation-archive.html">Donation Archive</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <a href="#">Shop</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"> <a href="shop.html">Shop</a> </li>
                                                <li className="menu-item"> <a href="product-single.html">Product Details</a> </li>
                                                <li className="menu-item"> <a href="cart.html">Cart</a> </li>
                                                <li className="menu-item"> <a href="checkout.html">Checkout</a> </li>
                                                <li className="menu-item"> <a href="wishlist.html">Wishlist</a> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <a href="contact-us.html">Contact</a>
                                        </li>
                                    </ul>
                                    <div className="sigma_header-controls style-2 p-0 border-0">
                                        <ul className="sigma_header-controls-inner pe-3">
                                            <li className="sigma_header-cart style-2">
                                                <a href="cart.html"> <i className="fal fa-shopping-bag" /> </a>
                                                <span className="number">1</span>
                                                <ul className="cart-dropdown">
                                                    <li>
                                                        <a href="#" className="sigma_cart-product-wrapper">
                                                            <img src="assets/img/products/1.jpg" alt="prod1" />
                                                            <div className="sigma_cart-product-body">
                                                                <h6>Gita Book</h6>
                                                                <div className="sigma_product-price justify-content-start">
                                                                    <span>29$</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="sigma_cart-product-wrapper">
                                                            <img src="assets/img/products/5.jpg" alt="prod1" />
                                                            <div className="sigma_cart-product-body">
                                                                <h6>Ramayana Book</h6>
                                                                <div className="sigma_product-price justify-content-start">
                                                                    <span>35$</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="sigma_cart-product-wrapper">
                                                            <img src="assets/img/products/4.jpg" alt="prod1" />
                                                            <div className="sigma_cart-product-body">
                                                                <h6>Mahabharat Book</h6>
                                                                <div className="sigma_product-price justify-content-start">
                                                                    <span>50$</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="sigma_header-wishlist style-2">
                                                <a href="wishlist.html"> <i className="fal fa-heart" /> </a>
                                                <ul className="cart-dropdown">
                                                    <li>
                                                        <p className="mb-0">You don't have any items</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Controls */}
                            <div className="sigma_header-controls style-2">
                                <a href="donation.html" className="sigma_btn-custom"> Donate Here</a>
                                <ul className="sigma_header-controls-inner">
                                    {/* Mobile Toggler */}
                                    <li className="aside-toggler style-2 aside-trigger-left">
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    {/* Middle Header End */}
                </header>
                {/* partial */}
                {/* partial:partia/__subheader.html */}
                <div className="sigma_subheader dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(assets/img/subheader.jpg)' }}>
                    <div className="container">
                        <div className="sigma_subheader-inner">
                            <div className="sigma_subheader-text">
                                <h1>Service 2</h1>
                            </div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="btn-link" href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Service 2</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* partial */}
                {/* holi Start */}
                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/5.jpg" alt="img" />
                                        <i className="flaticon-hindu" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Darshan</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/6.jpg" alt="img" />
                                        <i className="flaticon-powder-1" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Puja</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/7.jpg" alt="img" />
                                        <i className="flaticon-hindu-2" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Prashad</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/8.jpg" alt="img" />
                                        <i className="flaticon-pooja" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Car Puja</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/9.jpg" alt="img" />
                                        <i className="flaticon-arti" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Bhoomi Puja</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <a href="holi-details.html" className="sigma_service style-2">
                                    <div className="sigma_service-thumb">
                                        <img src="assets/img/service/10.jpg" alt="img" />
                                        <i className="flaticon-powder" />
                                    </div>
                                    <div className="sigma_service-body">
                                        <h5>Marriage</h5>
                                        <p>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* holi End */}
                {/* Clients Start */}
                <div className="section section-padding primary-bg pattern-map">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="sigma_client">
                                            <img src="assets/img/clients/1.png" alt="client" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="sigma_client">
                                            <img src="assets/img/clients/2.png" alt="client" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="sigma_client">
                                            <img src="assets/img/clients/3.png" alt="client" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sigma_client">
                                            <img src="assets/img/clients/4.png" alt="client" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="sigma_client">
                                            <img src="assets/img/clients/5.png" alt="client" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Clients End */}
                {/* CTA Start */}
                <div className="section section-padding">
                    <div className="container">
                        <div className="section-title text-center">
                            <p className="subtitle">WAYS WE CAN HELP</p>
                            <h4 className="title">Angels Ready To Help</h4>
                        </div>
                        <div className="row align-items-center position-relative">
                            <div className="col-md-6">
                                <div className="sigma_cta primary-bg">
                                    <img className="d-none d-lg-block" src="assets/img/cta/1.png" alt="cta" />
                                    <div className="sigma_cta-content">
                                        <span className="fw-600 custom-secondary">Need Help, Call Our HOTLINE!</span>
                                        <h4 className="text-white">+1 212-683-9756</h4>
                                    </div>
                                </div>
                            </div>
                            <span className="sigma_cta-sperator d-none d-lg-flex">or</span>
                            <div className="col-md-6">
                                <div className="sigma_cta primary-bg">
                                    <div className="sigma_cta-content">
                                        <form method="post">
                                            <label className="mb-0 text-white">Temple Newsletter</label>
                                            <div className="sigma_search-adv-input">
                                                <input type="text" className="form-control" placeholder="Enter email address" name="search" defaultValue />
                                                <button type="submit" name="button"><i className="fa fa-search" /></button>
                                            </div>
                                        </form>
                                    </div>
                                    <img className="d-none d-lg-block" src="assets/img/cta/2.png" alt="cta" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    );
}

export default Home;
